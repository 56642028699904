import React from 'react'
import Text from '@component/Text/Text'
import {CreateCopyModalState} from '@feature/copy/components/CreateCopyModal/CreateCopyModal'
import UserThumbnail from '@component/images/UserThumbnail'
import Span from '@component/Text/Span'
import {useTranslation} from 'next-i18next'
import useFormatNumber from '@hook/useFormatNumber'
import ExchangeTag from '@component/tags/ExchangeTag'
import {getNumberColorClass} from '@util/etc'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {useRouter} from 'next/router'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import IconNext from '@svg/common/ic_next.svg'
import IconDownArrow from '@svg/common/ic_down_arrow.svg'

interface IProps extends CreateCopyModalState {
    className?: string
    userBalance?: number
}

const CreateCopyReviewContainer: React.FC<IProps> = ({
    className,
    selectLeader,
    selectCopier,
    isEnableMirror,
    isEnableTakeProfit,
    isEnableStopLoss,
    takeProfitPercentage,
    stopLossPercentage,
    userBalance,
}) => {
    const router = useRouter()
    const {t} = useTranslation()
    const {data: me} = useQueryFetchMe()

    const {getExchange} = useQueryGetExchanges()

    const {displayPercent, displayBalance} = useFormatNumber()

    const getMirrorOptionText = () => {
        if (isEnableMirror) return t('modal.createCopy.step4.review.mirror.on')
        else return t('modal.createCopy.step4.review.mirror.off')
    }

    const renderStopLossView = () => {
        if (isEnableStopLoss)
            return (
                <div className={'flex gap-x-[3px] justify-end'}>
                    <Text className={'text-gray03 dark:text-dark_gray03 text-ti1'}>
                        {t('modal.createCopy.step4.review.est', {
                            balance: displayBalance(userBalance * (stopLossPercentage / 100) * -1.0, {
                                showPreSign: true,
                            }),
                        })}
                    </Text>
                    <Text className={`text-ti1 ${getNumberColorClass(stopLossPercentage * -1.0)}`}>
                        {displayPercent(stopLossPercentage * -1.0)}
                    </Text>
                </div>
            )
        else
            return (
                <Text className={'text-right text-ti1 text-gray01 dark:text-dark_gray01'}>
                    {t('modal.createCopy.step4.review.stopLoss.off')}
                </Text>
            )
    }
    const renderTakeProfitView = () => {
        if (isEnableTakeProfit)
            return (
                <div className={'flex gap-x-[3px] justify-end'}>
                    <Text className={'text-gray03 dark:text-dark_gray03 text-ti1'}>
                        {t('modal.createCopy.step4.review.est', {
                            balance: displayBalance(userBalance * (takeProfitPercentage / 100), {
                                showPreSign: true,
                            }),
                        })}
                    </Text>
                    <Text className={`text-right text-ti1 ${getNumberColorClass(takeProfitPercentage)}`}>
                        {displayPercent(takeProfitPercentage)}
                    </Text>
                </div>
            )
        else
            return (
                <Text className={'text-right text-ti1 text-gray01 dark:text-dark_gray01'}>
                    {t('modal.createCopy.step4.review.takeProfit.off')}
                </Text>
            )
    }

    return (
        <div className={`${className}`}>
            <div
                className={
                    'bg-gray09 dark:bg-bg_dark_white02 border border-gray06 dark:border-dark_gray06 rounded-[5px] pt-[30px] px-[70px] sm:px-[20px] pb-[20px]'
                }>
                <div className={`flex sm:flex-col sm:gap-y-[10px]`}>
                    <div className={'flex-1 flex flex-col items-center'}>
                        <div className={'relative'}>
                            <UserThumbnail src={me?.thumbnail} size={60} />
                            <div
                                className={
                                    'absolute bottom-0 right-[-3px] bg-blue dark:bg-dark_blue py-[2.5px] px-[5px] rounded-[10px]'
                                }>
                                <Text className={'text-ti4 text-white'}>{t('keyCopyfolio.copyTradingOption.me')}</Text>
                            </div>
                        </div>

                        <Text className={'mt-[5px] sm:mt-[0px]'}>
                            <Span className={'ml-[5px] text-ti2 text-gray01 dark:text-dark_gray01'}>
                                {selectCopier?.copierKey?.name ||
                                    getExchange(selectCopier?.copierKey.exchange)?.display_name}
                            </Span>
                        </Text>
                    </div>
                    <div
                        className={'flex-none flex flex-col justify-center sm:flex-row sm:gap-x-[5px] sm:items-center'}>
                        <IconNext className={'w-[30px] fill-blue dark:fill-dark_blue mx-auto sm:hidden'} />
                        <IconDownArrow className={'w-[20px] fill-blue dark:fill-dark_blue hidden sm:block'} />
                        <Text className={'text-ti2 text-blue dark:text-dark_blue mt-[5px] text-center sm:mt-[0px]'}>
                            {t('keyCopyfolio.copyTradingOption.copy')}
                        </Text>
                    </div>
                    <div className={'flex-1 flex flex-col items-center'}>
                        <div className={'relative'}>
                            <UserThumbnail src={selectLeader?.leaderKey?.thumbnail} size={60} />
                            <div
                                className={
                                    'absolute bottom-0 left-[40px] bg-leader dark:bg-dark_leader py-[2.5px] px-[5px] rounded-[10px]'
                                }>
                                <Text className={'text-ti4 text-white'}>
                                    {t('keyCopyfolio.copyTradingOption.leader')}
                                </Text>
                            </div>
                        </div>
                        <Text className={'mt-[5px] sm:mt-[0px]'}>
                            <Span className={'ml-[5px] text-ti2 text-gray01 dark:text-dark_gray01'}>
                                {selectLeader?.leaderKey?.copy_lead_name}
                            </Span>
                        </Text>
                    </div>
                </div>

                <div className={'mt-[20px] grid grid-cols-2 gap-y-[13.5px]'}>
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.createCopy.step4.review.exchange.title')}
                    </Text>
                    <div className={'flex justify-end items-center'}>
                        <ExchangeTag exchange={selectCopier?.copierKey.exchange} textClassName={'text-ti1'} />
                    </div>
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.createCopy.step4.review.mirror.title')}
                    </Text>
                    <Text className={'text-right text-ti1 text-gray01 dark:text-dark_gray01'}>
                        {getMirrorOptionText()}
                    </Text>
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.createCopy.step4.review.stopLoss.title')}
                    </Text>
                    {renderStopLossView()}
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.createCopy.step4.review.takeProfit.title')}
                    </Text>
                    {renderTakeProfitView()}
                </div>
            </div>
        </div>
    )
}

export default CreateCopyReviewContainer
