import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import SelectLeaderKeyItem from '@feature/copy/components/CreateCopyModal/SelectLeaderKeyContainer/SelectLeaderKeyItem/SelectLeaderKeyItem'
import {ICopyLeaderStartCopy} from '@api/copy/leader/getLeaderKeysForStartCopy'
import {useTranslation} from 'next-i18next'
import {equalString, isEmpty, isNotEmpty} from '@util/strings'
import useQueryGetLeaderKeysStartCopy from '@hook/query/portfolio/leaderfolio/useQueryGetLeaderKeysStartCopy'
import {useRouter} from 'next/router'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
    leaderUserID: string
    onChanged?: (key: ISelectLeaderRes) => void
    selectedID?: string
}

export interface ISelectLeaderRes {
    leaderKey: ICopyLeaderStartCopy
    verified: boolean
    errorMessage?: string
}

const SelectLeaderKeyContainer: React.FC<IProps> = ({className, leaderUserID, onChanged, selectedID}) => {
    const router = useRouter()
    const defaultSelectId = router.query?.id as string
    const {t} = useTranslation()
    const {data: keys} = useQueryGetLeaderKeysStartCopy(leaderUserID)
    const isInitDefaultOption = useRef(false)
    const scrollDivRef = useRef(null)

    const sortedKeyList = useMemo(() => {
        if (isNotEmpty(defaultSelectId)) {
            const list: ICopyLeaderStartCopy[] = []
            const defaultItem = keys?.find(item => item.id === defaultSelectId)
            if (defaultItem) {
                list.push(defaultItem)
            }
            keys?.filter(item => !equalString(item.id, defaultSelectId))?.map(item => list.push(item))
            return list
        } else {
            return keys
        }
    }, [keys, defaultSelectId])

    const onClickItem = useCallback(
        (item: ICopyLeaderStartCopy) => {
            if (item?.max_copier > item?.copier) {
                createLog('event', 'start_copy_trading_step1_select_leader_key', {leader_id: item?.id})
                onChanged({leaderKey: item, verified: true})
            } else {
                createLog('error', 'start_copy_trading_step1_select_leader_key_error_copier_limit', {
                    leader_id: item?.id,
                })
                onChanged({
                    leaderKey: item,
                    verified: false,
                    errorMessage: t('modal.createCopy.step1.error.copierLimit'),
                })
            }
        },
        [onChanged, t],
    )

    useEffect(() => {
        if (isInitDefaultOption.current === true) return

        if (isEmpty(defaultSelectId)) {
            isInitDefaultOption.current = true
            return
        }

        if (isEmpty(selectedID) && isNotEmpty(defaultSelectId) && keys) {
            const defaultItem = keys?.find(item => item.id === defaultSelectId)
            if (defaultItem) {
                onClickItem(defaultItem)
            }

            isInitDefaultOption.current = true
            return
        }
    }, [selectedID, defaultSelectId, keys, onClickItem])

    return (
        <div className={'relative mt-[10px]'}>
            <div className={``} ref={scrollDivRef}>
                {sortedKeyList?.map(item => (
                    <SelectLeaderKeyItem
                        key={item?.id}
                        {...item}
                        isSelected={selectedID === item?.id}
                        onClick={onClickItem}
                    />
                ))}
            </div>
        </div>
    )
}

export default SelectLeaderKeyContainer
