import React from 'react'
import {useTranslation} from 'next-i18next'
import WizCheckBox from '@component/inputs/WizCheckBox'
import WizMarkdown from '@component/markdown/WizMarkdown'

interface IProps {
    className?: string
    isCheckConfirmCopyTradingTerms?: boolean
    onChangeCheckConfirmCopyTradingTerms?: (isCheck?: boolean) => void
    terms?: string
}

const CreateCopyTermsAndConditionsContainer: React.FC<IProps> = ({
    className,
    isCheckConfirmCopyTradingTerms,
    onChangeCheckConfirmCopyTradingTerms,
    terms,
}) => {
    const {t} = useTranslation()

    return (
        <div className={'mt-[30px]'}>
            <div
                className={`mt-[10px] px-[40px] pt-[10px] pb-[30px] sm:mt-[0px] sm:px-[20px] sm:pt-[0px] sm:pb-[20px] bg-gray09 dark:bg-bg_dark_white02 border border-gray06 dark:border-dark_gray06 rounded-[5px] ${className}`}>
                <WizMarkdown
                    content={terms}
                    className={'text-gray02 dark:text-dark_gray02 text-body2'}
                    customClassName={{
                        h3: 'text-[16px] leading-[24px] text-gray01 dark:text-dark_gray01 pt-[0px] mt-[20px]',
                        ul: 'text-gray02 dark:text-dark_gray02 mt-[0px] mb-[0px] pt-[5px] pb-[0px] ml-[10px] pl-[10px]',
                        ol: 'text-gray02 dark:text-dark_gray02 mt-[0px] mb-[0px] pt-[5px] pb-[0px] ml-[10px] pl-[10px]',
                        li: 'text-gray02 dark:text-dark_gray02',
                    }}
                />
            </div>
            <div className={'mt-[30px] mb-[10px] flex justify-center'}>
                <WizCheckBox
                    text={t('modal.createCopy.step5_term.checkbox.text')}
                    checked={isCheckConfirmCopyTradingTerms}
                    onChanged={check => onChangeCheckConfirmCopyTradingTerms(check)}
                    textClassName={'text-body2 text-gray01 dark:text-dark_gray01'}
                />
            </div>
        </div>
    )
}

export default CreateCopyTermsAndConditionsContainer
