import React, {useEffect, useMemo, useState} from 'react'
import {IMyCopiableKey} from '@api/copy/mine/getMyCopiableKeys'
import SelectCopierKeyItem from '@feature/copy/components/CreateCopyModal/SelectCopierKeyContainer/SelectCopierKeyItem/SelectCopierKeyItem'
import {ICopyLeaderStartCopy} from '@api/copy/leader/getLeaderKeysForStartCopy'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import {equalString} from '@util/strings'
import {apiRefreshKey} from '@api/key/portfolio/refreshKey'
import useQueryGetCopiableKeys from '@hook/query/portfolio/useQueryGetCopiableKeys'
import TagText from '@component/Text/TagText'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import WizButton from '@component/buttons/WizButton'
import useNavigation from '@hook/useNavigation'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
    leader?: ICopyLeaderStartCopy
    onChanged?: (key: ISelectCopierRes) => void
    selectedID?: string
}

export interface ISelectCopierRes {
    copierKey: IMyCopiableKey
    verified: boolean
    errorMessage?: string
}

const SelectCopierKeyContainer: React.FC<IProps> = ({className, leader, onChanged, selectedID}) => {
    const {t} = useTranslation()
    const {getExchange} = useQueryGetExchanges()
    const {data: keys, refresh: refreshKeys} = useQueryGetCopiableKeys('all', leader?.id)
    const [isValid, setIsValid] = useState(false)
    const {goApi} = useNavigation()

    const sortedKeys = useMemo(() => {
        const list: IMyCopiableKey[] = []

        const sameExchangeItems = keys
            ?.filter(item => equalString(item.exchange, leader?.exchange))
            .sort((a, b) => b.current_balance - a.current_balance)

        const otherExchangeItems = keys
            ?.filter(item => !equalString(item.exchange, leader?.exchange))
            .sort((a, b) => a.exchange?.localeCompare(b.exchange))

        list?.push(...(sameExchangeItems ?? []))
        list?.push(...(otherExchangeItems ?? []))

        return list
    }, [keys, leader?.exchange])

    useEffect(() => {
        // 처음 Copy Modal을 열었을 때, 거래 권한이 없는 API 새로고침
        const sameExchangeKeys = sortedKeys?.filter(item => equalString(item.exchange, leader?.exchange))
        sameExchangeKeys?.map(async item => {
            if (item?.trade_permission !== true) {
                await apiRefreshKey(item?.id)
            }
        })
        refreshKeys()
    }, [sortedKeys, refreshKeys, leader])

    useEffect(() => {
        const sameExchangeItems = keys?.filter(item => equalString(item.exchange, leader?.exchange))

        const isCheckValid = sameExchangeItems?.find(item => {
            if (
                leader?.exchange === item?.exchange &&
                item?.current_balance >= leader?.min_balance &&
                leader?.copier < leader?.max_copier &&
                item?.is_copier === false &&
                item?.is_leader === false &&
                item?.is_autobot === false &&
                item?.is_unified_account === false &&
                item?.trade_permission === true &&
                item?.total_balance >= leader?.min_balance
            ) {
                return item
            }
        })

        if (isCheckValid) {
            setIsValid(true)
        }
    }, [keys, leader?.copier, leader?.exchange, leader?.max_copier, leader?.min_balance])

    return (
        <div className={className}>
            {sortedKeys?.length === 0 ? (
                <>
                    <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                        {t('modal.createCopy.step2.keys.hasNotApi.title')}
                    </Text>
                </>
            ) : isValid ? (
                <>
                    <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                        {t('modal.createCopy.step2.keys.title')}
                    </Text>
                    <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.createCopy.step2.keys.desc')}
                    </Text>
                </>
            ) : (
                <>
                    <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                        {t('modal.createCopy.step2.keys.useNotApi.title')}
                    </Text>
                    <TagText className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.createCopy.step2.keys.useNotApi.text')}
                    </TagText>
                </>
            )}
            <div className={`mt-[10px] sm:mt-[20px] mb-[20px]`}>
                {sortedKeys?.map(item => (
                    <SelectCopierKeyItem
                        key={item.id}
                        {...item}
                        leaderKey={leader}
                        isSelected={selectedID === item.id}
                        onChanged={onChanged}
                        refreshCallback={() => refreshKeys()}
                    />
                ))}

                {sortedKeys?.length === 0 && (
                    <div
                        className={
                            'border border-gray06 dark:border-dark_gray06 rounded-[5px] bg-gray09 dark:bg-dark_gray09 p-[20px] mb-[20px]'
                        }>
                        <div>
                            <TagText className={'text-gray02 dark:text-dark_gray02 text-body2 text-center'}>
                                {t('modal.createCopy.step2.keys.hasNotApi.text', {
                                    exchange: getExchange(leader?.exchange)?.display_name,
                                })}
                            </TagText>
                        </div>
                        <WizButton
                            text={t('modal.createCopy.step2.keys.hasNotApi.button')}
                            size={'small'}
                            buttonType={'border'}
                            className={'mx-auto mt-[10px]'}
                            onClick={() => {
                                createLog('event', 'start_copy_trading_step2_connect_exchange')
                                goApi()
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default SelectCopierKeyContainer
