import React, {useEffect, useRef} from 'react'
import WizSwitch from '@component/switchs/WizSwitch'
import WizCheckBox from '@component/inputs/WizCheckBox'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import WizNumberControl from '@component/inputs/WizNumberControl'
import {equalString} from '@util/strings'
import useFormatNumber from '@hook/useFormatNumber'
import Span from '@component/Text/Span'

interface IProps {
    className?: string
    exchange?: string
    enableMirrorMode?: boolean
    enableStopLoss?: boolean
    enableTakeProfit?: boolean
    stopLossPercentage?: number
    takeProfitPercentage?: number
    onChangeMirrorMode?: (isEnable: boolean) => void
    onChangeStopLoss?: (isEnable: boolean) => void
    onChangeTakeProfit?: (isEnable: boolean) => void
    onChangeStopLossPercentage?: (percentage: number) => void
    onChangeTakeProfitPercentage?: (percentage: number) => void
    isErrorStopLoss?: boolean
    isErrorTakeProfit?: boolean
    userBalance?: number
}

const SelectCopyOptionContainer: React.FC<IProps> = ({
    className,
    exchange,
    enableMirrorMode,
    enableStopLoss,
    enableTakeProfit,
    onChangeMirrorMode,
    onChangeStopLoss,
    onChangeTakeProfit,
    stopLossPercentage,
    takeProfitPercentage,
    onChangeStopLossPercentage,
    onChangeTakeProfitPercentage,
    isErrorStopLoss,
    isErrorTakeProfit,
    userBalance,
}) => {
    const {t} = useTranslation()
    const {displayBalance} = useFormatNumber()
    const stopLossRef = useRef<any>()
    const takeProfitRef = useRef<any>()

    useEffect(() => {
        if (isErrorStopLoss) {
            stopLossRef?.current?.focus()
        } else if (isErrorTakeProfit) {
            takeProfitRef?.current?.focus()
        }
    }, [isErrorStopLoss, isErrorTakeProfit])

    return (
        <>
            <div className={`${className}`}>
                <div className={'mt-[10px] flex space-x-[10px]'}>
                    <WizSwitch checked={enableMirrorMode} onChanged={checked => onChangeMirrorMode(checked)} />
                    <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                        {t('modal.createCopy.step3.mirror.label')}
                    </Text>
                </div>

                {enableMirrorMode && (
                    <div className={'py-[15px] px-[20px]'}>
                        <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                            {t('modal.createCopy.step3.mirror.on.desc.title')}
                        </Text>
                        <ul className={'list-disc list-outside pl-[15px]'}>
                            <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                <Span className={'text-gray02 dark:text-dark_gray02'}>
                                    {t('modal.createCopy.step3.mirror.on.desc.option1')}
                                </Span>
                            </li>
                            <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                <Span className={'text-gray02 dark:text-dark_gray02'}>
                                    {t('modal.createCopy.step3.mirror.on.desc.option2')}
                                </Span>
                            </li>
                            <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                <Span className={'text-gray02 dark:text-dark_gray02'}>
                                    {t('modal.createCopy.step3.mirror.on.desc.option3')}
                                </Span>
                            </li>
                            <li className={'text-body2 font-bold text-red_shade dark:text-dark_red_shade'}>
                                <Span className={'text-red_shade dark:text-dark_red_shade'}>
                                    {t('modal.createCopy.step3.mirror.on.desc.option4')}
                                </Span>
                            </li>
                        </ul>
                    </div>
                )}

                {!enableMirrorMode && (
                    <div className={'py-[15px] px-[20px]'}>
                        <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                            {t('modal.createCopy.step3.mirror.off.desc.title')}
                        </Text>
                        <ul className={'list-disc list-outside pl-[15px]'}>
                            <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                <Span className={'text-gray02 dark:text-dark_gray02'}>
                                    {t('modal.createCopy.step3.mirror.off.desc.option1')}
                                </Span>
                            </li>
                            <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                <Span className={'text-gray02 dark:text-dark_gray02'}>
                                    {t('modal.createCopy.step3.mirror.off.desc.option2')}
                                </Span>
                            </li>
                            <li className={'text-body2 font-bold text-gray02 dark:text-dark_gray02'}>
                                <Span className={'text-gray02 dark:text-dark_gray02'}>
                                    {t('modal.createCopy.step3.mirror.off.desc.option3')}
                                </Span>
                            </li>
                        </ul>
                    </div>
                )}

                <div className={'mt-[30px]'}>
                    <WizCheckBox
                        checked={enableStopLoss}
                        textClassName={'text-ti1 text-gray01 dark:text-dark_gray01'}
                        text={t('modal.createCopy.step3.stopLoss.label')}
                        onChanged={checked => onChangeStopLoss(checked)}>
                        <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.createCopy.step3.stopLoss.desc')}
                        </Text>
                        {!enableStopLoss && (
                            <Text className={'text-body2 text-red_shade dark:text-dark_red_shade'}>
                                {t('modal.createCopy.step3.stopLoss.notice')}
                            </Text>
                        )}
                    </WizCheckBox>
                    <div
                        className={
                            'flex flex-col items-center bg-gray09 dark:bg-bg_dark_white02 rounded-[5px] px-[30px] py-[20px] mt-[10px]'
                        }>
                        <div className={'space-y-[10px]'}>
                            <Text className={'text-btn text-center dark:text-dark_gray01'}>
                                {t('modal.createCopy.step3.stopLoss.controller.label')}
                            </Text>
                            <WizNumberControl
                                ref={stopLossRef}
                                disabled={!enableStopLoss}
                                num={stopLossPercentage}
                                className={`${isErrorStopLoss && 'border-red dark:border-dark_red'}`}
                                textClassName={'text-gray01 dark:text-dark_gray01'}
                                min={0}
                                max={100}
                                stepSize={1}
                                onChange={number => onChangeStopLossPercentage(number)}
                            />
                        </div>
                        {enableStopLoss && (
                            <div className={'mt-[5px]'}>
                                <Text className={'text-gray02 dark:text-dark_gray02 text-body2'}>
                                    {t('modal.createCopy.step3.stopLoss.controller.est', {
                                        balance: displayBalance(userBalance * (stopLossPercentage / 100), {
                                            showPreSign: true,
                                        }),
                                    })}
                                </Text>
                            </div>
                        )}
                        {equalString(exchange, 'bithumb') && (
                            <Text className={'text-body2 text-gray02 dark:text-dark_gray02 mt-[10px]'}>
                                {t('modal.createCopy.step3.stopLoss.controller.desc')}
                            </Text>
                        )}
                    </div>
                </div>

                <div className={'mt-[30px]'}>
                    <WizCheckBox
                        checked={enableTakeProfit}
                        textClassName={'text-ti1 text-gray01 dark:text-dark_gray01'}
                        text={t('modal.createCopy.step3.takeProfit.label')}
                        onChanged={checked => onChangeTakeProfit(checked)}>
                        <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.createCopy.step3.takeProfit.desc')}
                        </Text>
                    </WizCheckBox>
                    <div
                        className={
                            'flex flex-col items-center bg-gray09 dark:bg-bg_dark_white02 rounded-[5px] px-[30px] py-[20px] mt-[10px]'
                        }>
                        <div className={'space-y-[10px]'}>
                            <Text className={'text-btn text-center text-gray01 dark:text-dark_gray01'}>
                                {t('modal.createCopy.step3.takeProfit.controller.label')}
                            </Text>
                            <WizNumberControl
                                ref={takeProfitRef}
                                disabled={!enableTakeProfit}
                                num={takeProfitPercentage}
                                className={'text-gray01 dark:text-dark_gray01'}
                                min={0}
                                stepSize={1}
                                onChange={number => onChangeTakeProfitPercentage(number)}
                            />
                        </div>
                        {enableTakeProfit && (
                            <div className={'mt-[5px]'}>
                                <Text className={'text-gray02 dark:text-dark_gray02 text-body2'}>
                                    {t('modal.createCopy.step3.takeProfit.controller.est', {
                                        balance: displayBalance(userBalance * (takeProfitPercentage / 100), {
                                            showPreSign: true,
                                        }),
                                    })}
                                </Text>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectCopyOptionContainer
