import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import ExchangeTag from '@component/tags/ExchangeTag'
import useFormatNumber from '@hook/useFormatNumber'
import UserThumbnail from '@component/images/UserThumbnail'
import {getNumberColorClass} from '@util/etc'
import {getDayDifference, getDayDifferenceFromToday} from '@util/dates'
import RiskTag from '@component/tags/RiskTag'
import useQueryGetLeaderInfo from '@hook/query/portfolio/leaderfolio/useQueryGetLeaderInfo'

interface IProps {
    className?: string
    leaderId?: string
}

const CreateCopyMyLeaderSummary: React.FC<IProps> = ({className, leaderId}) => {
    const {t} = useTranslation()
    const {displayBalance, displayPercent, displayNumber} = useFormatNumber()
    const {data: leader} = useQueryGetLeaderInfo(leaderId)

    const getLeaderStartedDay = () => {
        if (getDayDifferenceFromToday(leader?.start_at) < 1) {
            return t('modal.createCopy.step2.myCopyLeader.started.new')
        }

        return t('modal.createCopy.step2.myCopyLeader.started.date.format', {
            day: displayNumber(getDayDifference(new Date(Date.parse(leader?.start_at)), new Date())),
        })
    }

    return (
        <div
            className={`${className} bg-brown_tint01 dark:bg-dark_brown_tint01 border border-transparent02 dark:border-dark_transparent rounded-[5px] px-[30px] sm:px-[20px] py-[20px]`}>
            <div className={'flex gap-x-[15px] items-center'}>
                <UserThumbnail containerClassName={'sm:hidden'} src={leader?.thumbnail} size={60} />
                <div className={'grow'}>
                    <div className={'flex justify-between gap-x-[5px] sm:items-center'}>
                        <div className={'flex items-center gap-x-[10px] sm:flex-col sm:items-start'}>
                            <Text
                                className={`text-h2 text-gray01 dark:text-dark_gray01 ${
                                    leader?.name?.length > 12 ? 'sm:text-ti1' : 'sm:text-h3'
                                }`}>
                                {leader?.name}
                            </Text>
                        </div>
                        <Text
                            className={`text-h2 sm:text-h3 ${getNumberColorClass(
                                leader?.asset_summary?.['1000']?.profit,
                            )}`}>
                            {displayPercent(leader?.asset_summary?.['1000']?.profit)}
                        </Text>
                    </div>
                    <div className={'mt-[5px] flex justify-between items-center md:hidden'}>
                        <div className={'flex gap-x-[20px]'}>
                            <div className={'flex gap-x-[5px]'}>
                                <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                    {t('modal.createCopy.step2.myCopyLeader.started.label')}
                                </Text>
                                <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                    {getLeaderStartedDay()}
                                </Text>
                            </div>
                            <div className={'flex space-x-[5px] items-center'}>
                                <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                    {t('modal.createCopy.step2.myCopyLeader.mdd.label')}
                                </Text>
                                <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                    {displayPercent(leader?.mdd)}
                                </Text>
                                <RiskTag risk={leader?.risk} />
                            </div>
                        </div>
                        <div className={'flex space-x-[5px]'}>
                            <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                {t('modal.createCopy.step2.myCopyLeader.balance.label')}
                            </Text>
                            <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                {displayBalance(leader?.latest_balance?.total_amount_in_base, {showPreSign: true})}
                            </Text>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={
                    'bg-white dark:bg-bg_dark_white02 dark:bg-opacity-100 bg-opacity-50 rounded-[5px] px-[20px] sm:px-[15px] py-[10px] mt-[15px]'
                }>
                <Text className={'text-ti3 text-gray01 dark:text-dark_gray01'}>
                    {t('modal.createCopy.step2.myCopyLeader.requirements.title')}
                </Text>
                <div className={'sm:block grid grid-cols-2 mt-[5px] '}>
                    <div className={'flex items-center space-x-[10px] sm:justify-between'}>
                        <Text className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.createCopy.step2.myCopyLeader.requirements.exchange.label')}
                        </Text>
                        <ExchangeTag
                            exchange={leader?.exchanges?.length > 0 && leader?.exchanges[0]}
                            iconSize={12}
                            textClassName={'text-ti1'}
                            iconClassName={'sm:hidden'}
                        />
                        {leader?.exchanges?.at(0) === 'binance' ? (
                            <Text className={'text-gray02 dark:text-dark_gray02 pl-[5px] sm:hidden'}>
                                {t('modal.createCopy.step1.selectLeaderKeyItem.requirements.exchange.binance.label')}
                            </Text>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className={'flex items-center justify-end space-x-[10px] sm:justify-between'}>
                        <Text className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.createCopy.step2.myCopyLeader.requirements.minimumBalance.label')}
                        </Text>
                        <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                            {displayBalance(leader?.min_balance, {showPreSign: true})}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateCopyMyLeaderSummary
