import React, {useMemo} from 'react'
import useFormatNumber from '@hook/useFormatNumber'
import {useTranslation} from 'next-i18next'
import {IMyCopiableKey} from '@api/copy/mine/getMyCopiableKeys'
import Text from '@component/Text/Text'
import WizImage from '@component/images/WizImage'
import {ISelectCopierRes} from '@feature/copy/components/CreateCopyModal/SelectCopierKeyContainer/SelectCopierKeyContainer'
import {ICopyLeaderStartCopy} from '@api/copy/leader/getLeaderKeysForStartCopy'
import WizButton from '@component/buttons/WizButton'
import RefreshIcon from '@svg/common/ic_refresh.svg'
import {apiRefreshKey} from '@api/key/portfolio/refreshKey'
import useSnackbar from '@hook/useSnackbar'
import KeyValidTag from '@component/tags/KeyValidTag'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import IconErrorMessage from '@svg/common/ic_error_message.svg'
import {createLog} from '@util/logs'
import Info from '@svg/common/ic_info.svg'
import IconInfoDark from '@svg/common/ic_info_dark.svg'
import useDark from '@hook/useDark'
import WizTooltip from '@component/tooltip/WizTooltip'
import TagText from '@component/Text/TagText'

interface IProps extends IMyCopiableKey {
    className?: string
    isSelected?: boolean
    leaderKey?: ICopyLeaderStartCopy
    onChanged?: (key: ISelectCopierRes) => void
    refreshCallback?: () => void
}

const SelectCopierKeyItem: React.FC<IProps> = props => {
    const {
        className,
        isSelected = false,
        onChanged,
        id,
        name,
        exchange,
        current_balance,
        total_balance,
        leaderKey,
        is_copier,
        is_leader,
        is_autobot,
        is_unified_account,
        trade_permission,
        refreshCallback,
        owed_earning,
    } = props

    const {t} = useTranslation()
    const {displayBalance, displayPercent, displayNumber} = useFormatNumber()
    const {getExchange} = useQueryGetExchanges()
    const {showSnackbar, showErrorSnackbar} = useSnackbar()
    const {isRenderDark} = useDark()

    const isSameExchange = useMemo(() => leaderKey.exchange === exchange, [leaderKey, exchange])
    const hasEnoughBalance = useMemo(() => current_balance >= leaderKey?.min_balance, [current_balance, leaderKey])
    const hasEnoughCopierSheet = useMemo(() => leaderKey?.copier < leaderKey?.max_copier, [leaderKey])
    const hasNotCopying = useMemo(() => is_copier === false, [is_copier])
    const hasNotLeader = useMemo(() => is_leader === false, [is_leader])
    const NotUsingAutoBot = useMemo(() => is_autobot === false, [is_autobot])
    const hasNotUnifiedAccount = useMemo(() => is_unified_account === false, [is_unified_account])
    const hasTradePermission = useMemo(() => trade_permission === true, [trade_permission])
    const hasEnoughBalanceInExchangeAllWallet = useMemo(
        () => total_balance >= leaderKey?.min_balance,
        [total_balance, leaderKey],
    )

    const isValidKey = useMemo(() => {
        return (
            isSameExchange === true &&
            hasEnoughBalance === true &&
            hasEnoughCopierSheet === true &&
            hasNotCopying === true &&
            hasNotLeader === true &&
            NotUsingAutoBot === true &&
            hasNotUnifiedAccount === true &&
            hasTradePermission === true &&
            hasEnoughBalanceInExchangeAllWallet === true
        )
    }, [
        isSameExchange,
        hasEnoughBalance,
        hasEnoughCopierSheet,
        hasNotCopying,
        hasNotLeader,
        NotUsingAutoBot,
        hasNotUnifiedAccount,
        hasTradePermission,
        hasEnoughBalanceInExchangeAllWallet,
    ])

    const getErrorMessage = useMemo(() => {
        if (!isSameExchange) {
            return t('modal.createCopy.step2.keyItem.error.exchange')
        } else if (!hasNotUnifiedAccount) {
            return t('modal.createCopy.step2.keyItem.error.isUnifiedAccount')
        } else if (!hasEnoughBalance) {
            if (hasEnoughBalanceInExchangeAllWallet) {
                return t('modal.createCopy.step2.keyItem.error.shouldMoveMoneyToFuture')
            } else {
                return t('modal.createCopy.step2.keyItem.error.enoughBalance')
            }
        } else if (!hasEnoughCopierSheet) {
            return t('modal.createCopy.step2.keyItem.error.copierSheet')
        } else if (!hasNotCopying) {
            return t('modal.createCopy.step2.keyItem.error.alreadyCopier')
        } else if (!hasNotLeader) {
            return t('modal.createCopy.step2.keyItem.error.isLeader')
        } else if (!NotUsingAutoBot) {
            return t('modal.createCopy.step2.keyItem.error.usingAutobot')
        } else if (!hasTradePermission) {
            return t('modal.createCopy.step2.keyItem.error.noPermission')
        } else {
            return undefined
        }
    }, [
        t,
        isSameExchange,
        hasEnoughBalance,
        hasEnoughBalanceInExchangeAllWallet,
        hasEnoughCopierSheet,
        hasNotCopying,
        hasNotLeader,
        NotUsingAutoBot,
        hasNotUnifiedAccount,
        hasTradePermission,
    ])

    const onClickRefreshCopierKey = async () => {
        createLog('event', 'start_copy_trading_step2_refresh_copier_key', {copier_key: id})
        const {data, status, error} = await apiRefreshKey(id)
        if (data) {
            refreshCallback && refreshCallback()
            showSnackbar(t('modal.createCopy.step2.keyItem.refresh.done'))
        } else {
            createLog('error', 'start_copy_trading_step2_refresh_copier_key_faild', {copier_key: id})
            showErrorSnackbar(error)
        }
    }

    const getParentBackgroundStyle = () => {
        if (!isValidKey) {
            return 'bg-gray09 dark:bg-bg_dark_white02 border-gray06 dark:border-dark_gray06'
        } else {
            if (isSelected) {
                return 'bg-blue_tint02 dark:bg-dark_blue_tint02 border-blue dark:border-dark_blue shadow-normal'
            } else {
                return 'bg-white dark:bg-bg_dark_white01 border-gray06 dark:border-dark_gray06 hover:border-blue dark:hover:border-dark_blue'
            }
        }

        return ''
    }

    const getTextColor = (defaultText: string = 'text-gray01 dark:text-dark_gray01') => {
        if (isValidKey) {
            return defaultText
        } else {
            return 'text-gray04 dark:text-dark_gray04'
        }
    }

    return (
        <div
            className={`p-[20px] border rounded-[5px] cursor-pointer mb-[10px] ${getParentBackgroundStyle()}`}
            onClick={() => {
                onChanged({copierKey: {...props}, verified: isValidKey, errorMessage: getErrorMessage})
            }}>
            <div className={`flex justify-between items-center grid grid-cols-4 md:grid-cols-2`}>
                <div className={'flex items-center space-x-[10px] col-span-2'}>
                    <KeyValidTag status={isValidKey ? 'valid' : 'invalid'} />
                    <Text className={`text-body2 truncate ${getTextColor()}`}>{name || id}</Text>
                </div>
                <div className={'flex items-center space-x-[5px]'}>
                    <WizImage src={getExchange(exchange)?.icon} width={12} height={12} />
                    <Text className={`text-body2 ${getTextColor()}`}>{getExchange(exchange)?.display_name}</Text>
                </div>
                <div>
                    <Text className={`text-ti4 ${getTextColor('text-gray03 dark:text-dark_gray03')} text-right`}>
                        {t('modal.createCopy.step2.keyItem.balance.title')}
                    </Text>
                    <Text className={`text-ti2 ${getTextColor()} text-right`}>
                        {displayBalance(current_balance, {showPreSign: true})}
                    </Text>
                </div>
            </div>
            {getErrorMessage && (
                <div className={'flex items-center justify-between mt-[10px] md:grid gap-y-[10px] gap-x-[5px]'}>
                    <div className={'flex items-center gap-x-[10px]'}>
                        <IconErrorMessage className={'w-[16px] fill-red dark:fill-dark_red flex-none sm:hidden'} />
                        <Text className={'text-red_shade dark:text-dark_red_shade grow'}>{getErrorMessage}</Text>
                    </div>
                    <div className={'flex items-center'}>
                        <WizButton
                            buttonType={'border'}
                            text={t('modal.createCopy.step2.keyItem.refresh')}
                            size={'small'}
                            renderRightArea={
                                <RefreshIcon width={16} height={16} className={'fill-gray02 dark:fill-dark_gray02'} />
                            }
                            onClick={onClickRefreshCopierKey}
                        />
                    </div>
                </div>
            )}
            {owed_earning && (
                <>
                    <div className={'flex items-center justify-center gap-x-[5px] mt-[10px]'}>
                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                            {t('copyfolio.copyTrading.recovery.items.text')}
                        </Text>
                        <WizTooltip
                            type={'copytrading_loss_recovery_tooltip'}
                            title={t('copyfolio.copyTrading.recovery.modal.content.title')}
                            placement={'top'}
                            onClick={e => e.stopPropagation()}
                            render={
                                isRenderDark ? (
                                    <IconInfoDark
                                        className={'w-[20px] fill-bg_dark_white03 hover:fill-bg_dark_white01'}
                                    />
                                ) : (
                                    <Info className={'w-[20px] fill-white hover:fill-gray08'} />
                                )
                            }>
                            <TagText className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                {t('copyfolio.copyTrading.recovery.modal.content.text')}
                            </TagText>
                            <Text className={'text-ti2 mt-[20px] text-blue_shade dark:text-dark_blue_shade'}>
                                {t('copyfolio.copyTrading.recovery.modal.loss')}:{' '}
                                {displayBalance(owed_earning, {showPreSign: true, isUSD: true})}
                            </Text>
                        </WizTooltip>
                    </div>
                </>
            )}
        </div>
    )
}

export default SelectCopierKeyItem
